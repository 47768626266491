import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'
import ns1 from './locales/en_translation.json'
import componentsNs1 from 'mysaiva-web-components/src/i18next/locales/en_translation.json'

export const defaultNS = 'ns1'

i18next.use(initReactI18next).init({
  lng: 'en',
  debug: true,
  resources: {
    en: {
      ns1
    }
  },
  defaultNS
})

i18next.addResourceBundle('en', 'ns1', componentsNs1, true, false)

export { i18next }
export default i18next
